import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// local
import Tick from "../../assets/images/audit/tick.svg";
import InfraCarousal from "../../components/Common/InfraCarousal";
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import * as styles from "../../styles/bookdemo/Demo.module.scss";
import "../../styles/bookdemo/style.scss";

const AuditDemo = (props) => {
  const [finalLoad, setFinalLoad] = useState(false);
  const pathName = props.location.pathname;

  const loadForm = () => {
    if ("hbspt" in window && typeof window !== "undefined") {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "22580341",
        formId: "6655e55a-1ae1-4d1f-bfcc-1d01976da8b0",
        target: "#demo-form-audit",
      });
      setFinalLoad(true);
    }
  };

  useEffect(() => {
    loadForm();
  }, []);

  setTimeout(() => {
    loadForm();
  }, 1000);

  setTimeout(() => {
    if (!finalLoad) loadForm();
  }, 1400);

  return (
    <>
      <Layout pathName={pathName}>
        <Seo
          title="Lucidity Demo: Take the first step towards becoming truly cloud-ready."
          description="Request a live demo of Lucidity. Transform your block storage with 3 clicks and deliver 70% Cost Savings,
         99.999% Uptime,10X Performance, with zero code changes"
        />
        <section className={styles["demo"]}>
          <Container className={styles["demo__container"]}>
            <Row className="align-items-center">
              <Col lg={6} md={6} xs={12} className="text-left p-0">
                <div
                  className={`${styles["demo__heading"]} ${styles["demo__audit_heading"]} justify-content-center`}
                >
                  <h1>
                    Wastage is meant for your Bin, not your{" "}
                    <span>cloud storage</span>
                  </h1>
                </div>
                <p>Book a FREE storage Audit today and unearth:</p>
                <div className={styles["demo__audit_features"]}>
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>25 min Deployment time</span>
                  </div>

                  <div>
                    <img src={Tick} alt="tick" />
                    <span>No Access to Lucidity</span>
                  </div>
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>Ohh and also It’s Free!</span>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} xs={12} className="text-center p-0">
                <div className="custom-form-bnr" id="demo-form-audit">
                  <p className="initial_msg">Schedule a Demo </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles["demo_team_love"]}>
          <InfraCarousal />
        </section>
      </Layout>
    </>
  );
};

export default AuditDemo;
